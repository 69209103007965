import React, { useRef, useState, useEffect } from 'react';
import { BackButton } from '../components/UI/BackButton';
import LanguageBox from '../components/UI/LanguageSwitcher';
import jsQR from 'jsqr';
import plusIcon from '../assets/icons/plus.svg';
import axios from '../components/api';
import { Link } from 'react-router-dom';
import './FanoCard.css';

import arrowDown from '../assets/icons/arrowDown.svg';
import scansiona from '../assets/icons/scansiona.svg';
import fanoCard10gg from '../assets/FanoCard10gg.png';
import { FanoCard7gg } from './FanoCard7gg';
import { FanoCard14gg } from './FanoCard14gg';
import FormCartaFisica from '../components/fanocard/formCartaFisica';
import { useTranslation } from 'react-i18next';
import { url } from '../url';

export const FanoCardAddTest = () => {
  const { t } = useTranslation();

  const [qrValue, setQrValue] = useState('');
  const [isQrCode, setIsQrCode] = useState(false);
  const qrInputRef = useRef();
  const videoContainerRef = useRef();

  const [statusSendDateCard, setStatusSendDateCard] = useState(false);

  const [cardAvailable, setCardAvailable] = useState(false);
  const [allCards, setAllCards] = useState([]);

  useEffect(() => {
    document.addEventListener('deviceready', onDeviceReady, false);
  }, []);

  const onDeviceReady = () => {
    const permissions = cordova.plugins.permissions;
    const permission = permissions.CAMERA;

    permissions.checkPermission(permission, (status) => {
      if (status.hasPermission) {
        // Hai già ottenuto il permesso, puoi avviare l'utilizzo della fotocamera
      } else {
        permissions.requestPermission(permission, (status) => {
          if (status.hasPermission) {
            // Il permesso è stato concesso, puoi avviare l'utilizzo della fotocamera
          } else {
            // Il permesso è stato negato dall'utente
          }
        });
      }
    });
  };

  const getAllQRCodes = () => {
    const qrCodes = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith('qrCode')) {
        const qrCode = localStorage.getItem(key);
        qrCodes.push(qrCode);
      }
    }
    return qrCodes;
  };
  useEffect(() => {
    const qrCodes = getAllQRCodes();
    if (qrCodes.length > 0) {
      setCardAvailable(true);
    }

    const cards = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.includes('qrCode-')) {
        const card = JSON.parse(localStorage.getItem(key));
        cards.push(card);
      }
    }
    setAllCards(cards);
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const qrCodes = getAllQRCodes();
      if (qrCodes.length > 0) {
        setCardAvailable(true);
      }

      const cards = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.includes('qrCode-')) {
          const card = JSON.parse(localStorage.getItem(key));
          cards.push(card);
        }
      }
      setAllCards(cards);
    };

    // Aggiungi l'ascoltatore dell'evento 'storage' per monitorare le modifiche nel localStorage
    window.addEventListener('storage', handleStorageChange);

    // Rimuovi l'ascoltatore dell'evento quando il componente viene smontato
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  const handleInputChange = (e) => {
    setIsQrCode(false);
    const inputValue = e.target.value;
    const codeParts = inputValue.split('#');
    const extractedCode = codeParts[0];
    setQrValue(extractedCode);
    localStorage.setItem('currentCard', extractedCode);
  };

  const [isScanning, setIsScanning] = useState(false);

  const handleScanQR = async () => {
    try {
      if (isScanning) {
        // Interrompi la scansione
        setIsScanning(false);
        videoContainerRef.current.removeChild(video);
        stream.getTracks().forEach((track) => track.stop());
      } else {
        // Avvia la scansione
        setIsScanning(true);
        const constraints = {
          video: { facingMode: { exact: 'environment' }, width: { max: 640 } },
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        const video = document.createElement('video');
        video.classList.add('camerascan');
        videoContainerRef.current.appendChild(video);
        const buttonCloseCamera = document.createElement('button');
        buttonCloseCamera.classList.add('fixed');
        buttonCloseCamera.classList.add('bottom-10');
        buttonCloseCamera.classList.add('left-0');
        buttonCloseCamera.classList.add('z-[999999999999999]');
        buttonCloseCamera.classList.add('m-4');
        buttonCloseCamera.classList.add('bg-white');
        buttonCloseCamera.classList.add('px-4');
        buttonCloseCamera.classList.add('py-2');
        buttonCloseCamera.classList.add('rounded-full');
        buttonCloseCamera.classList.add('text-black');
        buttonCloseCamera.classList.add('hover:bg-gray-200');
        buttonCloseCamera.classList.add('focus:outline-none');
        buttonCloseCamera.classList.add('focus:ring-2');
        buttonCloseCamera.classList.add('focus:ring-offset-2');
        buttonCloseCamera.classList.add('focus:ring-indigo-500');
        buttonCloseCamera.innerHTML = `${t('chiudicamera')}`;
        buttonCloseCamera.addEventListener('click', closeCamera); // Add this line to handle the click event

        buttonCloseCamera.addEventListener('click', () => {
          closeCamera();
          videoContainerRef.current.removeChild(buttonCloseCamera); // Remove the button when it's clicked
        });

        videoContainerRef.current.appendChild(buttonCloseCamera);

        video.srcObject = stream;
        video.play();
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        const scanningInterval = setInterval(() => {
          if (video.readyState === video.HAVE_ENOUGH_DATA) {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageData = canvasContext.getImageData(0, 0, canvas.width, canvas.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height);
            if (code) {
              clearInterval(scanningInterval);
              videoContainerRef.current.removeChild(video);
              stream.getTracks().forEach((track) => track.stop());
              setIsQrCode(true);
              setQrValue(code.data);
            }
          }
        }, 300);
      }
    } catch (error) {
      console.log('Error scanning QR code:', error);
    }
  };

  const handleSubmit = async () => {
    const code = qrValue.trim();
    const qrCodes = getAllQRCodes();
    if (qrCodes.length > 0) {
      setCardAvailable(true);
    }
    if (code === '') {
      alert(`${t('inserisciilcodicedellacard')}`);
      return;
    }
    const codeParts = code.split('#');
    const extractedCode = codeParts[0];

    //se il codice é di 8 caratteeri fai una chiamata se é maggiore di 8 e contiene una @ fai un altra chiamata uguale peró a quella di 8
    if (code.length === 8) {
      try {
        const response = await fetch(`https://card.visitfano.info/it/fanocard/checkprecode.json?codice=${encodeURIComponent(code)}&uuid=${localStorage.getItem('uuid')}`);
        const data = await response.json();
        console.log(data);
        if (data.response_code === 20 || data.response_code === 21 || data.response_code === 22 || data.response_code === 23) {
          switch (data.response_code) {
            case 20:
              alert(`${t('cartanonvalida')}`);
              break;
            case 22:
              console.log(data);
              // alert("Carta già attiva, effettua la GET e salva le informazioni.");
              localStorage.setItem('currentCard', extractedCode);
              localStorage.setItem(`qrCode-${extractedCode}`, JSON.stringify(data));
              window.location.reload();

              // Esempio di come puoi salvare le informazioni ricevute dalla GET nel localStorage:
              // localStorage.setItem(
              //     `qrCode-${extractedCode}`,
              //     JSON.stringify(data)
              // );
              break;
            case 23:
              alert(`${t('lacartaegiaattivasuunaltrotelefonononpuoiattivarlasuquestotelefono')}`);
              break;
            case 21:
              localStorage.setItem('currentCard', extractedCode);
              setStatusSendDateCard(true);
              break;
            default:
              alert(`${t('erroresconosciutodurantelaverificadellacarta')}`);
              break;
          }
        } else {
          alert(`${t('erroredurantelaverificadellacarta')}`);
        }
      } catch (error) {
        console.log(`${t('erroredurantelaverificadellacarta')}`, error);
      }
    }
    if (code.length > 8 && code.includes('@')) {
      try {
        const response = await fetch(`https://card.visitfano.info/it/fanocard/checkvisitcard.json?codice=${encodeURIComponent(code.split('#')[0])}&uuid=${localStorage.getItem('uuid')}`);
        const data = await response.json();
        console.log(data);
        if (data.response_code === 26 || data.response_code === 23 || data.response_code === 25) {
          switch (data.response_code) {
            case 20:
              alert(`${t('cartanonvalida')}`);
              break;
            case 26:
              console.log(data);
              // alert("Carta già attiva, effettua la GET e salva le informazioni.");
              localStorage.setItem('currentCard', extractedCode);
              localStorage.setItem(`qrCode-${extractedCode}`, JSON.stringify(data));
              window.location.reload();

              // Esempio di come puoi salvare le informazioni ricevute dalla GET nel localStorage:
              // localStorage.setItem(
              //     `qrCode-${extractedCode}`,
              //     JSON.stringify(data)
              // );
              break;
            case 25:
              console.log(data);
              // alert("Carta già attiva, effettua la GET e salva le informazioni.");
              localStorage.setItem('currentCard', extractedCode);
              localStorage.setItem(`qrCode-${extractedCode}`, JSON.stringify(data));
              window.location.reload();

              // Esempio di come puoi salvare le informazioni ricevute dalla GET nel localStorage:
              // localStorage.setItem(
              //     `qrCode-${extractedCode}`,
              //     JSON.stringify(data)
              // );
              break;
            default:
              alert(`${t('erroresconosciutodurantelaverificadellacarta')}`);
              break;
          }
        } else {
          alert(`${t('erroredurantelaverificadellacarta')}`);
        }
      } catch (error) {
        console.log(`${t('erroredurantelaverificadellacarta')}`, error);
      }
    }
  };

  //aggiungo il controllo al click visualizzo un div al click lo rimuovo
  const currentLanguage = localStorage.getItem('i18nextLng');
  // console.log(currentLanguage)

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [height, setHeight] = useState('0');
  const [top, setTop] = useState('[540px]');
  const [padding, setPadding] = useState('0');
  const [paddingTop, setPaddingTop] = useState('0');
  const [paddingBottom, setPaddingBottom] = useState('0');

  function toggleHeight() {
    setHeight(height === '0' ? '50vh' : '0');
    setTop(top === '[580px]' ? '0' : '[580px]');
    setPadding(padding === '0' ? '5' : '0');
    setPaddingTop(paddingTop === '0' ? '4' : '0');
    setPaddingBottom(paddingBottom === '0' ? '4' : '0');
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    toggleHeight();
  };

  useEffect(() => {
    const cards = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.includes('qrCode-')) {
        const card = JSON.parse(localStorage.getItem(key));
        cards.push(card);
      }
    }
    setAllCards(cards);
  }, []);

  const closeCamera = () => {
    if (videoContainerRef.current && videoContainerRef.current.firstChild) {
      const videoElement = videoContainerRef.current.firstChild;
      const stream = videoElement.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      videoContainerRef.current.removeChild(videoElement);
    }
    setIsScanning(false);
  };

  useEffect(() => {
    return () => closeCamera();
  }, []);

  console.log(allCards);

  //faccio una fetch dove salvo in uno use state se il pulsante acquista deve essere attivo o no fetch /pulsante-acquista

  const [isBuyButtonActive, setIsBuyButtonActive] = useState(false);

  useEffect(() => {
    axios
      .get('${url}/pulsante-acquista')
      .then((response) => {
        setIsBuyButtonActive(response.data[0].pulsante_acquista);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  }, []);

  // console.log(isBuyButtonActive)

  return (
    <section className='bg-fanoBlue h-screen text-left w-full overflow-hidden overflow-x-hidden'>
      <div className={'px-5'}>
        {cardAvailable && (
          <div className='wallet'>
            <div className='flex flex-wrap justify-between items-center overflow-scroll h-[65vh] card-container'>
              <FanoCard7gg data={allCards} />
              {/*<FanoCard14gg data={allCards}/>*/}
            </div>
            <button className='w-full bg-white px-3 py-2 rounded-md text-fanoBlue font-medium h-[50px] rounded-[10px] w-full' onClick={toggleMenu}>
              <p className={'text-fanoBlue font-semibold'}>{t('aggiungiunaltracard')}</p>
              {/*<img src={plusIcon} alt={"Aggiungi Fano Card"} className={"h-6 w-6 mx-auto mb-3"} />*/}
            </button>

            {isBuyButtonActive === '0' && (
              <a href={'https://card.visitfano.info'} target={'_blank'} className={'leading-8 w-full bg-white px-3 py-2 rounded-md text-fanoBlue font-medium h-[50px] rounded-[10px] w-full block text-center mt-5'}>
                <p className={'text-fanoBlue font-semibold'}>{t('acquista')}</p>
              </a>
            )}
          </div>
        )}
        {!cardAvailable && (
          <div>
            <button className='px-3 py-2 rounded-md text-fanoBlue font-medium h-[300px] border-4 border-dashed rounded-[10px] w-full' onClick={toggleMenu}>
              <img src={plusIcon} alt={'Aggiungi Fano Card'} className={'h-6 w-6 mx-auto mb-3'} />
              <p className={'text-white'}>{t('aggiungilatuacfanovisitcard')}</p>
            </button>
            {isBuyButtonActive === '0' && (
              <a href={'https://card.visitfano.info'} target={'_blank'} className={'leading-8 w-full bg-white px-3 py-2 rounded-md text-fanoBlue font-medium h-[50px] rounded-[10px] w-full block text-center mt-5'}>
                <p className={'text-fanoBlue font-semibold'}>{t('acquista')}</p>
              </a>
            )}
          </div>
        )}
      </div>

      <div className='menu h-[20vh] mt-10 overflow-hidden'>
        <div className={`${isMenuOpen ? '' : 'hidden'} fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-[9999]`} id='my-modal' onClick={toggleMenu}></div>
        <div className={`${isMenuOpen ? 'bg-white ' : 'bg-fanoBlue delay-300'} absolute w-full bottom-0 overflow-scroll  z-[999999] b-t-l-r-15 pl-5 pr-5`}>
          <div className={`h-${height} top-${top} z-10 relative duration-300 pt-${paddingTop} pb-${paddingBottom}`}>
            <div className={'px-1 flex flex-wrap items-center mb-6 mt-3'}>
              <img src={arrowDown} className={'ml-1 mr-3 -rotate-90 cursor-pointer'} onClick={toggleMenu} />
              <p className={'font-semibold'}>{t('attivalafanovisitcard')}</p>
            </div>
            <div className={'px-1 mb-5'}>
              <p className={'text-black break-words'}>{t('inserisciilcodicechehairicevuto')}</p>
            </div>
            <div className={'flex flex-wrap justify-center items-center mb-5'}>
              <input type={'text'} style={{ backgroundColor: '#D3D3D3', color: '#000000' }} placeholder={t('inserisciilcodice')} className={'text-left w-full h-[50px] px-5 font-semibold text-black placeholder:text-black placeholder:font-normal relative z-10 rounded-[10px] shadow-fanoShadow'} value={qrValue} onChange={handleInputChange} ref={qrInputRef} />
            </div>
            <div className={'px-1 mb-5'}>
              <p className={'text-black break-words'}>{t('scannerizzailqrcode')}</p>
            </div>
            <div className={'flex flex-wrap justify-center items-center mt-5'}>
              <div className='mr-2 fixed z-[99999] object-cover' ref={videoContainerRef} />
              {/* Il video verrà inserito qui dal tuo script */}

              <button className='flex flex-wrap px-3 justify-center py-2 rounded-[10px] text-white bg-fanoBlue font-medium w-full mb-5 text-left px-5 h-[51px] text-[20px] font-roboto_slab font-bold' onClick={handleScanQR}>
                <img src={scansiona} className={'ml-1 mr-3 '} />
                {t('scansiona')}
              </button>
              <button className='bg-white px-3 py-2 rounded-md text-fanoBlue font-medium h-[51px] w-[100px] shadow-fanoShadow w-full border-fanoBlue border' onClick={handleSubmit}>
                {t('invia')}
              </button>
            </div>

            {statusSendDateCard && (
              <div className='flex flex-wrap justify-center items-center mt-5'>
                <FormCartaFisica />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FanoCardAddTest;
