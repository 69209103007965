import React, { useState, useEffect } from "react";

import backArrow from "../../assets/icons/backArrow.svg";
import { useTranslation } from "react-i18next";
import moment from "moment";

export function MonthDayFilter({
  events,
  setFilteredEvents,
  selectedDay,
  setSelectedDay,
  selectedMonth,
  setSelectedMonth,
  currentDate,
  setCurrentDate,
}) {
  const [isDayView, setIsDayView] = useState(true);

  /*   useEffect(() => {
    // On component mount, filter for no month
    filterByMonth(-1);
  }, [events, setFilteredEvents]);
 */
  const dayButtonRefs = React.useRef([]);
  const monthButtonRefs = React.useRef([]);
  const filterByMonth = (month) => {
    setSelectedMonth(month);
    setIsDayView(false);
    setCurrentDate(
      moment(new Date(new Date().getFullYear(), month, 1)).format("YYYY-MM-DD")
    );
    setSelectedDay(1);
  };

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setCurrentDate(
      moment(new Date(new Date().getFullYear(), selectedMonth, day)).format(
        "YYYY-MM-DD"
      )
    );
  };

  useEffect(() => {
    if (isDayView) {
      // Scrolla verso il bottone del giorno selezionato
      if (dayButtonRefs.current[selectedDay - 1]) {
        dayButtonRefs.current[selectedDay - 1].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
    // Scrolla verso il bottone del mese selezionato
    if (monthButtonRefs.current[selectedMonth]) {
      monthButtonRefs.current[selectedMonth].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedDay, selectedMonth, isDayView, events, setFilteredEvents]);

  const handleMonthClick = (month) => {
    filterByMonth(month);
    setIsDayView(true);
  };

  const backToMonthView = () => {
    setIsDayView(false);
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const daysInMonth = getDaysInMonth(new Date().getFullYear(), selectedMonth);

  const { t } = useTranslation();

  const MONTHS = [
    `${t("Gennaio")}`,
    `${t("Febbraio")}`,
    `${t("Marzo")}`,
    `${t("Aprile")}`,
    `${t("Maggio")}`,
    `${t("Giugno")}`,
    `${t("Luglio")}`,
    `${t("Agosto")}`,
    `${t("Settembre")}`,
    `${t("Ottobre")}`,
    `${t("Novembre")}`,
    `${t("Dicembre")}`,
  ];
  const DAYS_OF_WEEK = [
    `${t("lunedip")}`,
    `${t("martedip")}`,
    `${t("mercoledip")}`,
    `${t("giovedip")}`,
    `${t("venerdip")}`,
    `${t("sabatop")}`,
    `${t("domenicap")}`,
  ];

  return (
    <>
      <div className={"bg-white"}>
        {isDayView && (
          <button
            className={
              "p-2 bg-mazaraPrimary text-white rounded-[10px] w-[70px] font-semibold mb-5 flex font-roboto_slab text-[12px] h-[5vh] items-center"
            }
            onClick={backToMonthView}
          >
            <img className={"mr-2 "} src={backArrow} />
            <p className="">{t("mesi")}</p>
          </button>
        )}
        {isDayView && (
          <p
            className={
              "font-semibold text-mazaraPrimary mb-3 font-roboto_slab text-sm"
            }
          >
            {MONTHS[selectedMonth]}
          </p>
        )}
      </div>
      <div className={"h-full overflow-x-hidden"}>
        <div className="month-day-filter h-full overflow-auto overflow-x-hidden">
          {!isDayView &&
            MONTHS.map((month, i) => (
              <button
                key={`month_${i}`}
                ref={(ref) => (monthButtonRefs.current[i] = ref)}
                className={`calendar-day-button font-semibold font-roboto_slab border border-mazaraPrimary mb-5 h-[70px] w-[70px] rounded-[10px] text-mazaraPrimary text-sm ${
                  i === selectedMonth
                    ? "bg-mazaraPrimary text-white"
                    : "bg-white"
                }`}
                onClick={() => handleMonthClick(i)}
              >
                {month}
              </button>
            ))}
          {isDayView &&
            Array.from({ length: daysInMonth }, (_, i) => i + 1).map((day) => {
              const date = new Date(
                new Date().getFullYear(),
                selectedMonth,
                day
              );
              const adjustedIndex = (date.getDay() + 6) % 7;
              const dayOfWeek = DAYS_OF_WEEK[adjustedIndex];

              return (
                <div key={day} className="calendar-day-container">
                  <button
                    ref={(ref) => (dayButtonRefs.current[day - 1] = ref)}
                    onClick={() => handleDayClick(day)}
                    className={`calendar-day-button border font-semibold font-roboto_slab border-mazaraPrimary mb-5 h-[70px] w-[70px] rounded-[10px] text-mazaraPrimary ${
                      day === selectedDay
                        ? "bg-mazaraPrimary text-white"
                        : "bg-white"
                    }`}
                  >
                    {day}
                    <p className="calendar-day-label">{dayOfWeek}</p>
                  </button>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
