import axios from 'axios';
import { t, use } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import { BackButton } from '../UI/BackButton';
import VideoCard from './VideoCard';
import VisibileConFanoCard from '../UI/VisibileConFanoCard';
import { url } from '../../url';

function VideoPlaylist() {
  // get params from url
  const { id } = useParams();
  const [videoPlaylist, setVideoPlaylist] = useState([]);
  const [video360Playlist, setVideo360Playlist] = useState([]);
  const location = useLocation();
  const locationVideo = location.state?.videoPlaylist;
  const locationVideo360 = location.state?.video360Playlist;

  useEffect(() => {
    if (locationVideo) {
      setVideoPlaylist(locationVideo);
    }
    if (locationVideo360) {
      setVideo360Playlist(locationVideo360);
    }
  }, [locationVideo, locationVideo360]);

  const [isLoading, setIsLoading] = useState(false);

  /*   useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.get(
          `${url}/it/api/media/video/${id}`
        );
        if (response.data?.length > 0) {
          setVideoPlaylist(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchVideo360Data = async () => {
      try {
        const response = await axios.get(
          `${url}/it/api/media/video360/${id}`
        );
        if (response.data?.length > 0) {
          setVideo360Playlist(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!!id) {
      fetchVideoData();
      fetchVideo360Data();
    }
  }, [id]); */

  return (
    <div className={'h-screen overflow-hidden'}>
      {isLoading ? (
        <div className='flex justify-center items-center h-screen'>
          <BarLoader color={'#2b6cb0'} size={50} />
        </div>
      ) : (
        <>
          <div className={'flex flex-wrap h-[12vh] content-end relative z-0 p-5 bg-fanoBlue'}>
            <div className={'flex flex-wrap items-center relative w-full  '}>
              <BackButton sfondo={'false'} />
              <span className={'text-white font-semibold text-[20px] ml-4'}>{t('Playlist video')}</span>
              {/*  <Link to={"/calendario"} className={"absolute right-0"}>
                <img src={list} />
              </Link> */}
            </div>
          </div>
          <div
            className={'flex flex-col items-center overflow-y-auto py-10'}
            style={{
              maxHeight: 'calc(100vh - 12vh)',
              minHeight: 'calc(100vh - 12vh)',
            }}
          >
            {videoPlaylist.length > 0 && (
              <>
                {/*       <h2 className={"text-fanoBlue text-[20px] font-bold pt-4 pb-3"}>
                  Video{" "}
                </h2> */}
                <div className={'flex flex-col justify-center items-center'}>
                  {videoPlaylist.map((video, i) => {
                    return (
                      <VisibileConFanoCard
                        withFanoCard={parseInt(video.visibile_con_fanocard)}
                        customClass={'my-3 border border-gray-400'}
                        customStyle={{
                          maxWidth: window.innerWidth - 50,
                          height: 200,
                        }}
                        key={`video_${i}`}
                      >
                        <VideoCard videoUrl={video.video} type={'Video'} withFanoCard={parseInt(video.visibile_con_fanocard)} titolo={video.titolo} immagine_antemprima={video.immagine_anteprima} />
                      </VisibileConFanoCard>
                    );
                  })}
                </div>
              </>
            )}
            {video360Playlist.length > 0 && (
              <>
                {/*  <h2
                  className={
                    "text-fanoBlue text-[20px] font-bold pt-3 pb-3 self-start"
                  }
                >
                  Video 360
                </h2> */}
                <div className={'flex flex-wrap justify-center items-center'}>
                  {video360Playlist.map((video, i) => {
                    return <VideoCard videoUrl={video.video360} key={`video360_${i}`} type={'Video 360'} titolo={video.titolo} immagine_antemprima={video.immagine_anteprima} withFanoCard={parseInt(video.visibile_con_fanocard)} />;
                  })}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default VideoPlaylist;
