import Header from "../components/UI/Header";
import InEvidenza from "../components/homepage/InEvidenza";
import Menu from "../components/UI/Menu";
import { useMediaQuery } from "react-responsive";
import HeaderTotem from "../components/UI/HeaderTotem";
import InEvidenzaTotem from "../components/homepage/InEvidenzaTotem";
import MenuTotem from "../components/UI/MenuTotem";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import MapComponentAroundMe from "../components/mappaDellaCitta/MapComponentAroundMe";
import ScopriFano from "../components/homepage/ScopriFano";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const [selectedButton, setSelectedButton] = useState("evidenza");
  const [geolocationEnabled, setGeolocationEnabled] = useState(true);
  const [evidenzaActive, setEvidenzaActive] = useState(true);
  const [aroundmeActive, setAroundmeActive] = useState(false);
  const [scopriActive, setScopriActive] = useState(false);

  const handleClick = (buttonName) => {
    setSelectedButton(buttonName);
    setEvidenzaActive(buttonName === "evidenza");
    setAroundmeActive(buttonName === "aroundme");
    setScopriActive(buttonName === "scopri");
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      setGeolocationEnabled(true);
    }
  }, []);

  return (
    <section id={"homepage"} className={"h-screen"}>
      {isDesktop ? (
        <div className={"h-20vh bg-mazaraPrimary"}>
          <HeaderTotem />
        </div>
      ) : (
        <Header />
      )}
      {isDesktop ? (
        <div className={"flex justify-start ml-6 mt-5 mb-5 h-[0vh]"}>
          {/*<button*/}
          {/*    onClick={() => handleClick('evidenza')}*/}
          {/*>*/}
          {/*<span className={`text-left font-semibold pl-2 pr-2 pt-0 pb-0 rounded-6px ${evidenzaActive ? 'text-white bg-fanoBlue' : 'text-fanoBlue bg-white'}`}>*/}
          {/*  {t('evidenza')}*/}
          {/*</span>*/}
          {/*              </button>*/}
        </div>
      ) : (
        <div className={"flex justify-start ml-6 mt-5 mb-5 h-[5vh]"}>
          <button onClick={() => handleClick("evidenza")}>
            <span
              className={`mr-2 text-left font-semibold px-2 py-1 rounded-6px ${
                evidenzaActive
                  ? "text-white bg-mazaraSecondary"
                  : "text-mazaraSecondary bg-white"
              }`}
            >
              {t("evidenza")}
            </span>
          </button>
          {geolocationEnabled ? (
            <button onClick={() => handleClick("aroundme")}>
              <span
                className={`mr-2 text-left font-semibold px-2 py-1 rounded-6px ${
                  aroundmeActive
                    ? "text-white bg-mazaraSecondary"
                    : "text-mazaraSecondary bg-white"
                }`}
              >
                {t("aroundme")}
              </span>
            </button>
          ) : (
            <p>Per accedere alla mappa devi abilitare la geolocalizzazione.</p>
          )}
          {/*   <button onClick={() => handleClick("scopri")}>
            <span
              className={`mr-2 text-left font-semibold px-2 py-1 rounded-6px ${
                scopriActive
                  ? "text-white bg-mazaraSecondary"
                  : "text-mazaraSecondary bg-white"
              }`}
            >
              {t("scopriMazara")}
            </span>
          </button> */}
        </div>
      )}

      {isDesktop ? (
        <div className={"h-[55vh]"}>
          {selectedButton === "evidenza" ? <InEvidenzaTotem /> : null}
        </div>
      ) : selectedButton === "evidenza" ? (
        <InEvidenza />
      ) : null}
      {isDesktop ? (
        <></>
      ) : selectedButton === "aroundme" ? (
        <MapComponentAroundMe />
      ) : null}
      {isDesktop ? <></> : selectedButton === "scopri" ? <ScopriFano /> : null}

      {isDesktop ? (
        <div className={"h-[25vh] bg-mazaraPrimary"}>
          <MenuTotem />
        </div>
      ) : (
        <Menu />
      )}
    </section>
  );
};

export default Home;
