import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';
import backButtonImage from '../../assets/icons/backArrow.svg';
import fanoLogo from '../../assets/fano.png';

function LanguageSwitcher() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [isItalianSelected, setIsItalianSelected] = useState(i18n.language === 'it');
  const [isEnglishSelected, setIsEnglishSelected] = useState(i18n.language === 'en');
  const [showConfirmation, setShowConfirmation] = useState(false);

  function handleItalianChange(event) {
    const isChecked = event.target.checked;
    setIsItalianSelected(isChecked);
    if (isChecked) {
      setIsEnglishSelected(false);
      setShowConfirmation(true);
    }
  }

  function handleEnglishChange(event) {
    const isChecked = event.target.checked;
    setIsEnglishSelected(isChecked);
    if (isChecked) {
      setIsItalianSelected(false);
      setShowConfirmation(true);
    }
  }

  function handleConfirm() {
    i18n.changeLanguage(isItalianSelected ? 'it' : 'en');
    setShowConfirmation(false);
    window.location.href = '/';
  }

  function handleCancel() {
    setIsItalianSelected(i18n.language === 'it');
    setIsEnglishSelected(i18n.language === 'en');
    setShowConfirmation(false);
  }
  return (
    <div className='flex flex-wrap relative p-5'>
      <span className={'text-white font-semibold mb-4'}>{t('lingua')}</span>

      <label className='block items-center w-full h-10 relative flex items-center'>
        <input type='checkbox' className='custom-checkbox h-5 w-5 text-gray-600' checked={isItalianSelected} onChange={handleItalianChange} />
        <span className='ml-2 text-gray-700 text-white'>Italiano</span>
      </label>
      <label className='block items-center w-full h-10 relative flex items-center'>
        <input type='checkbox' className='custom-checkbox h-5 w-5 text-gray-600' checked={isEnglishSelected} onChange={handleEnglishChange} />
        <span className='ml-2 text-gray-700 text-white'>English</span>
      </label>
      {showConfirmation && ( // show confirmation popup only if showConfirmation is true
        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-center justify-center min-h-screen'>
            <div className={'bg-black opacity-60 absolute w-full min-h-screen z-0'} />
            <div className='bg-white rounded-lg shadow-lg p-5 mx-2 relative z-10'>
              <h2 className='text-lg font-medium mb-4'>Cambiando la lingua l'app verrá riavviata</h2>
              <p className='mb-4'>Sei sicuro di voler cambiare la lingua?</p>
              <div className=''>
                <button className='bg-mazaraPrimary text-white font-medium px-4 py-2 rounded-6px w-full mb-5' onClick={handleConfirm}>
                  Continua
                </button>
                <button className='text-black font-medium px-4 py-2 rounded-6px w-full' onClick={handleCancel}>
                  Annulla
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LanguageSwitcher;
