import axios from 'axios';
import { useEffect, useState } from 'react';
import InEvidenzaCarousel from './InEvidenzaCarousel';

const InEvidenza = () => {
  return (
    <div className={'h-[59vh] mt-[-20px]'}>
      <InEvidenzaCarousel />
    </div>
  );
};

export default InEvidenza;
