import mazaraLogo from '../../assets/logo_mazzara_white.png';
import HamburgerMenu from './HamburgerMenu';
import { Link } from 'react-router-dom';
import search from '../../assets/icons/search.svg';
const Header = () => {
  return (
    <div className={'bg-mazaraPrimary flex flex-wrap h-[16vh] content-center items-end p-8'}>
      <div className={'flex flex-wrap'}>
        <HamburgerMenu />
      </div>
      <img className={'w-[150px] h-auto m-auto'} src={mazaraLogo} />
      <Link to={'/cerca'}>
        <img src={search} alt={'search'} className={'mb-2'} />
      </Link>
    </div>
  );
};

export default Header;
