import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import TotemComponent from './TotemComponent';
import AppComponent from './AppComponent';
import { useMediaQuery } from 'react-responsive';
import Screensaver from './pages/Screensaver';
import videoSource from './assets/video.mp4'; // Sostituisci con il percorso corretto del tuo video

function App() {
  const [videoLoaded, setVideoLoaded] = useState(false); // Stato per il caricamento del video
  const [videoEnded, setVideoEnded] = useState(false); // Stato per la fine del video
  const [videoClicked, setVideoClicked] = useState(false); // Stato per il click sul video
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });
  const videoRef = useRef(null);

  // if(videoEnded === true) {
  useEffect(() => {
    setVideoEnded(sessionStorage.getItem('videoEnded')); // Imposta la fine del video se è presente nella sessione
  }, []);
  // }
  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('loadeddata', handleVideoLoad);
      videoElement.addEventListener('ended', handleVideoEnd);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadeddata', handleVideoLoad);
        videoElement.removeEventListener('ended', handleVideoEnd);
      }
    };
  }, []);

  const handleVideoLoad = () => {
    setVideoLoaded(true); // Imposta lo stato del caricamento del video su true
  };

  const handleVideoEnd = () => {
    setVideoEnded(true); // Imposta lo stato della fine del video su true
    sessionStorage.setItem('videoEnded', true); // Imposta la fine del video nella sessione
  };

  const handleVideoClick = () => {
    if (!videoClicked) {
      const videoElement = videoRef.current;
      if (videoElement && videoElement.duration) {
        const targetTime = videoElement.duration - 2; // Imposta il tempo di destinazione agli ultimi 2 secondi
        videoElement.currentTime = targetTime; // Imposta la posizione del video agli ultimi 2 secondi
        videoElement.play(); // Avvia il video
        setVideoClicked(true); // Imposta lo stato del click sul video su true
      }
    }
  };

  return (
    <div className='App h-screen'>
      {isDesktop && <Screensaver />}

      {!isDesktop && !videoEnded && (
        <video
          id='video'
          ref={videoRef}
          className={`video ${videoLoaded ? 'h-100vh w-full object-cover' : 'hidden'}`}
          playsInline={true}
          muted
          autoPlay
          onEnded={handleVideoEnd}
          onClick={handleVideoClick} // Aggiungi l'evento di click al video
          onTouchStart={handleVideoClick}
          src={videoSource}
          style={{ width: '100%', height: '100%' }}
          controls={false} // Aggiungi l'attributo controls per mostrare i controlli del video
        ></video>
      )}
      {!isDesktop && videoEnded && <AppComponent />}
      {/*{!isDesktop && <AppComponent />}*/}
      {isDesktop && <TotemComponent />}
    </div>
  );
}

export default App;
