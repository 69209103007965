import React, { useEffect, useState } from 'react';
import axios from '../api';
import { Link } from 'react-router-dom';
import doveDormireWhite from '../../assets/icons/doveDormireWhite.svg';
import BarLoader from 'react-spinners/BarLoader';
import eventiERassegneIcon from '../../assets/icons/eventiERassegneWhite.svg';
import newsIcon from '../../assets/icons/newsWhite.svg';
import museiECulturaIcon from '../../assets/icons/museiECulturaWhite.svg';
import percorsiIcon from '../../assets/icons/itinerariTuristiciWhite.svg';
import doveMangiareIcon from '../../assets/icons/doveMangiareWhite.svg';
import doveDormireIcon from '../../assets/icons/doveDormireWhite.svg';
import shoppingIcon from '../../assets/icons/shoppingWhite.svg';
import itinerariIcon from '../../assets/icons/itinerariTuristiciWhite.svg';
import stabilimentiBalneariIcon from '../../assets/icons/stabilimentiBalneariWhite.svg';
import { url } from '../../url';

export const MappaDellaCittaCard = (props) => {
  // console.log(props.data)
  // console.log(props.filter)
  // const currentLanguage = localStorage.getItem("i18nextLng");
  // const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(0);
  // const pageSize = 10; // numero di elementi per pagina
  // const eventsPerPage = 1000; // limite massimo di eventi da recuperare per ogni chiamata all'API
  // const [hasMoreData, setHasMoreData] = useState(true);
  //
  // useEffect(() => {
  //     setIsLoading(true);
  //     axios
  //         .get(
  //             `/mappa?page=${currentPage}&pageSize=${pageSize}`
  //         )
  //         .then((response) => {
  //             setData((prevData) => [...prevData, ...response.data.rows]);
  //             setTotalPages(response.data.pager.total_pages);
  //             if (currentPage === response.data.pager.total_pages) {
  //                 setHasMoreData(false);
  //             }
  //             setIsLoading(false);
  //         })
  //         .catch((error) => console.log(error));
  // }, [currentPage, currentLanguage, pageSize]);
  //
  // async function fetchAllEvents() {
  //     const allEvents = [];
  //     const response = await axios.get(
  //         `/mappa?page=1&pageSize=1`
  //     );
  //     const totalEvents = response.data.pager.total_items;
  //     const maxEventsToFetch = Math.min(eventsPerPage, totalEvents - data.length);
  //     const maxPagesToFetch = Math.ceil(maxEventsToFetch / pageSize);
  //     for (let i = 0; i <= maxPagesToFetch; i++) {
  //         const response = await axios.get(
  //             `/mappa?page=${i}&pageSize=${pageSize}`
  //         );
  //         allEvents.push(...response.data.rows);
  //     }
  //     return allEvents;
  // }
  //
  // useEffect(() => {
  //     if (hasMoreData && data.length < eventsPerPage) {
  //         setIsLoading(true);
  //         fetchAllEvents().then((allEvents) => {
  //             setData(allEvents);
  //             const totalEvents = allEvents.length;
  //             setHasMoreData(totalEvents < totalEvents);
  //             setIsLoading(false);
  //         });
  //     }
  // }, [data.length, eventsPerPage, hasMoreData, currentLanguage]);
  const getDetailLink = (data) => {
    if (!data || !data.type || !data.id) {
      return null;
    }
    let link = null;

    switch (data.type) {
      case 'eventi':
        link = `/eventi-e-rassegne/${data.id}`;
        break;
      case 'musei_e_cultura':
        link = `/musei-e-cultura/${data.id}`;
        break;
      case 'punti_di_interesse':
        link = `/punti-di-interesse/${data.id}`;
        break;
      case 'dove_mangiare':
        link = `/dove-mangiare/${data.id}`;
        break;
      case 'dove_dormire':
        link = `/dove-dormire/${data.id}`;
        break;
      case 'sapori_e_shopping':
        link = `/sapori-e-shopping/${data.id}`;
        break;
      case 'stabilimenti_balneari':
        link = `/stabilimenti-balneari/${data.id}`;
        break;
      default:
        link = `/dettaglio/${data.id}`;
        break;
    }

    return link;
  };

  const getDetailImage = (location) => {
    if (!location || !location.type || !location.id) {
      return null;
    }
    let src = null;
    switch (location.type) {
      case 'eventi':
        src = eventiERassegneIcon;
        break;
      case 'rassegna':
        src = eventiERassegneIcon;
        break;
      case 'news':
        src = newsIcon;
        break;
      case 'musei_e_cultura':
        src = museiECulturaIcon;
        break;
      case 'punti_di_interesse':
        src = percorsiIcon;
        break;
      case 'dove_mangiare':
        src = doveMangiareIcon;
        break;
      case 'dove_dormire':
        src = doveDormireIcon;
        break;
      case 'sapori_e_shopping':
        src = shoppingIcon;
        break;
      case 'percorsi_turistici':
        src = itinerariIcon;
        break;
      case 'stabilimenti_balneari':
        src = stabilimentiBalneariIcon;
        break;
    }

    return src;
  };

  const getTypeBackground = (location) => {
    if (!location || !location.type || !location.id) {
      return null;
    }
    let src = null;
    switch (location.type) {
      case 'eventi':
        src = 'bg-fanoMarkerBlu';
        break;
      case 'rassegna':
        src = 'bg-fanoMarkerBlu';
        break;
      case 'news':
        src = 'bg-fanoMarkerBlu';
        break;
      case 'musei_e_cultura':
        src = 'bg-fanoMarkerRosso';
        break;
      case 'punti_di_interesse':
        src = 'bg-fanoMarkerBlu';
        break;
      case 'dove_mangiare':
        src = 'bg-fanoMarkerArancione';
        break;
      case 'dove_dormire':
        src = 'bg-fanoMarkerArancione';
        break;
      case 'sapori_e_shopping':
        src = 'bg-fanoMarkerArancione';
        break;
      case 'percorsi_turistici':
        src = 'bg-fanoMarkerBlu';
        break;
      case 'stabilimenti_balneari':
        src = 'bg-fanoMarkerAzzurro';
        break;
    }

    return src;
  };
  return (
    <div className={'lg:h-[41vh] flex lg:flex-wrap overflow-auto pb-5 snap-x overflow-x-auto'}>
      {props.data.map((doveDormireList, i) => (
        <div key={i} className={'flex w-[25vh] lg:flex-wrap lg:basis-1/3 lg:basis-1/3 rounded-[10px] p-2 mb-5 snap-center shrink-0 first:pl-4 last:pr-4'}>
          <Link to={`${getDetailLink(doveDormireList)}`} className={'w-full'}>
            <div className={'shadow-fanoShadow rounded-[10px] w-full relative h-full'}>
              <div className={`${getTypeBackground(doveDormireList)} absolute bg-fanoBlue top-0 right-0 p-2 rounded-[10px]`}>
                <img src={`${getDetailImage(doveDormireList)}`} className={'w-6 h-6'} />
              </div>
              <img src={`${url}${doveDormireList.field_media_image_export}`} className={'w-full h-64 lg:h-[28rem] object-cover rounded-[10px]'} />
              <div className={'flex flex-wrap justify-center items-center px-4 py-2'}>
                <h1 className={'text-fanoBlue text-[15px] font-roboto_slab font-bold pt-3 pb-3'}>{doveDormireList.titolo}</h1>
              </div>
            </div>
          </Link>
        </div>
      ))}
      {isLoading && (
        <div className='flex justify-center items-center h-44'>
          <BarLoader color={'#2b6cb0'} size={25} />
        </div>
      )}
    </div>
  );
};
