import { useEffect, useState } from 'react';
import axios from '../api';
import { Link } from 'react-router-dom';
import doveDormireWhite from '../../assets/icons/doveDormireWhite.svg';
import BarLoader from 'react-spinners/BarLoader';
import { useMediaQuery } from 'react-responsive';
import SearchBlue from '../../assets/icons/searchBlue.svg';
import { useTranslation } from 'react-i18next';
import VisibileConFanoCard from '../UI/VisibileConFanoCard';
import Select from 'react-select';
import { url } from '../../url';

// sostituire entity  &amp; con &
const decodeHtml = (html) => {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

export const DoveDormireCard = () => {
  const currentLanguage = localStorage.getItem('i18nextLng');
  const [data, setData] = useState([]);
  const [dataTaxonomy, setDataTaxonomy] = useState([]);
  const [dataTaxonomySelected, setDataTaxonomySelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10; // numero di elementi per pagina
  const eventsPerPage = 50; // limite massimo di eventi da recuperare per ogni chiamata all'API
  const [hasMoreData, setHasMoreData] = useState(true);

  useEffect(() => {
    const fetchTaxonomy = async () => {
      try {
        const response = await axios.get(`${url}/${currentLanguage}/api/tassonomia/dove-dormire/`);
        setDataTaxonomy(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchTaxonomy();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${url}/${currentLanguage}/api/dove-dormire?page=${currentPage}&pageSize=${pageSize}`);
        setData((prevData) => [...prevData, ...response.data.rows]);
        setTotalPages(response.data.pager.total_pages);
        if (currentPage === response.data.pager.total_pages) {
          setHasMoreData(false);
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    if (hasMoreData && data.length < eventsPerPage) {
      fetchData();
    }
  }, [currentPage, currentLanguage, pageSize, hasMoreData, data.length, eventsPerPage]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 200 && hasMoreData) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasMoreData]);

  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });
  const [searchQuery, setSearchQuery] = useState('');
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const { t } = useTranslation();

  return (
    <section className={'flex flex-wrap w-full justify-start h-full'}>
      {isDesktop ? (
        <div className={'hidden'}></div>
      ) : (
        <>
          <div className={'flex flex-wrap h-[50px] w-full -mt-[40px] mb-5 relative z-[99999]'}>
            <Link to='/cerca?category=dove_dormire' className={'w-full'}>
              <img src={SearchBlue} alt={'SearchBlue'} className={'absolute left-4 top-1/2 transform -translate-y-1/2 z-20'} />
              <input type={'text'} placeholder={t('cerca')} className={'text-left w-full h-[50px] px-5 pl-14 font-semibold relative z-10 rounded-[10px] shadow-fanoShadow'} />
            </Link>
          </div>
          {dataTaxonomy?.length > 0 && (
            <Select
              styles={{
                container: (provided) => ({
                  ...provided,

                  width: '100%',
                  marginBottom: '12px',
                  borderRadius: '50px',
                }),
                control: (provided) => ({
                  ...provided,
                  border: 'none',
                  boxShadow: '0px 4px 8px rgba(0,0,0,0.15)',
                  borderRadius: '10px',
                  minHeight: '50px',
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontWeight: 600,
                  color: '#aaa',
                }),
                multiValue: (provided) => ({
                  ...provided,
                  backgroundColor: '#13577C',
                  color: '#fff',
                }),
                multiValueLabel: (provided) => ({
                  ...provided,
                  color: '#fff',
                  fontWeight: 600,
                }),
              }}
              placeholder={t('sceglilatipologia')}
              isMulti
              options={dataTaxonomy.map((item) => ({
                value: item.tid,
                label: decodeHtml(item.nome),
              }))}
              onChange={(e) => {
                setDataTaxonomySelected(e);
              }}
            />
          )}
        </>
      )}
      <div className={'flex flex-wrap overflow-auto h-full pb-5 w-full'}>
        {data
          ?.filter((event) => event.titolo.toLowerCase().includes(searchQuery.toLowerCase()))
          .filter((event) => {
            if (dataTaxonomySelected.length === 0) {
              return true;
            } else {
              return dataTaxonomySelected.some((item) => event.tipologia_dove_dormire_tid.some((tassonomia) => tassonomia === item.value));
            }
          }).length === 0 ? (
          <>
            <div className={'flex flex-wrap justify-center items-center w-full h-full'}>
              <h2 className={'text-fanoBlue text-[20px] font-roboto_slab font-bold'}>{t('nessunrisultatocorrispondente')}</h2>
            </div>
          </>
        ) : (
          data
            ?.filter((event) => event.titolo.toLowerCase().includes(searchQuery.toLowerCase()))
            .filter((event) => {
              if (dataTaxonomySelected.length === 0) {
                return true;
              } else {
                return dataTaxonomySelected.some((item) => event.tipologia_dove_dormire_tid.some((tassonomia) => tassonomia === item.value));
              }
            })
            .map((doveDormireList, i) => (
              <div key={i} className={'flex flex-wrap basis-1/2 lg:basis-1/3 lg:basis-1/3 rounded-[10px] p-2 mb-5'}>
                <VisibileConFanoCard rounded={'rounded-[10px]'}>
                  <Link to={`/dove-dormire/${doveDormireList.id}`} className={'w-full'}>
                    <div className={'shadow-fanoShadow rounded-[10px] w-full relative h-full'}>
                      <div className={'absolute bg-fanoMarkerArancione top-0 right-0 p-2 rounded-[10px]'}>{isDesktop ? <img src={doveDormireWhite} className={'                            w-10 h-10'} /> : <img src={doveDormireWhite} className={'                            w-6 h-6'} />}</div>
                      <img src={`${url}${doveDormireList.immagine_anteprima}`} className={'w-full h-[250px] lg:h-[28rem] object-cover rounded-[10px]'} />
                      <div className={'flex flex-wrap justify-center items-center px-4 py-2'}>{isDesktop ? <h1 className={'text-fanoBlue text-[25px] font-roboto_slab font-bold pt-3 pb-3'}>{doveDormireList.titolo}</h1> : <h1 className={'text-fanoBlue text-[15px] font-roboto_slab font-bold pt-3 pb-3'}>{doveDormireList.titolo}</h1>}</div>
                    </div>
                  </Link>
                </VisibileConFanoCard>
              </div>
            ))
        )}

        {isLoading && (
          <div className='flex justify-center items-center h-44'>
            <BarLoader color={'#2b6cb0'} size={25} />
          </div>
        )}
      </div>
    </section>
  );
};
