import React, { useState } from 'react';
import { AudioPlayer } from './AudioPlayer';
import { url } from '../../url';

const AudioCard = ({ audioUrl, titolo, immagine_antemprima, setSelectedAudio, selectedAudio, mid_export }) => {
  const previewWidth = window.innerWidth - 50;
  const previewHeight = 200;

  const audioStyle = {
    width: previewWidth + 'px',
    height: previewHeight + 'px',
    cursor: 'pointer',
    pointerevents: 'none',
    display: !!selectedAudio && selectedAudio !== mid_export ? 'none' : 'block',
    /*     display: isAudioPlayerOpen ? "none" : "block", */
    backgroundImage: `url(${url}/${immagine_antemprima})`,
    backgrounPosition: 'bottom center',
  };

  return (
    <div style={audioStyle} className={'my-3 relative border border-gray-400 static'}>
      <AudioPlayer setSelectedAudio={setSelectedAudio} audioUrl={`${url}/${audioUrl}`} titolo={titolo} mid_export={mid_export} />
    </div>
  );
};

export default AudioCard;
