import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { url } from '../../url';
const Gallery = ({ images }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setCurrentImageIndex(0);
    setModalIsOpen(false);
  };

  const handleSlide = (index) => {
    setCurrentImageIndex(index);
  };

  const handleThumbnailClick = (event, index) => {
    setCurrentImageIndex(index);
  };

  const customRenderItem = (item) => {
    return (
      <div className='image-gallery-image'>
        <img src={`${url}${item.field_media_image_export}`} alt='' style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }} />
      </div>
    );
  };

  let settings;
  if (Array.isArray(images) && images.length > 0) {
    settings = {
      items: images,
      showFullscreenButton: true,
      showPlayButton: false,
      showIndex: true,
      showBullets: true,
      showThumbnails: true,
      thumbnailPosition: 'bottom',
      startIndex: currentImageIndex,
      onSlide: handleSlide,
      onThumbnailClick: handleThumbnailClick,
      renderItem: customRenderItem,
    };
  } else {
    settings = {
      items: [
        {
          field_media_image_export: '',
        },
      ],
      showFullscreenButton: false,
      showPlayButton: false,
      showIndex: false,
      showBullets: false,
      showThumbnails: false,
      thumbnailPosition: 'bottom',
      startIndex: 0,
      onSlide: handleSlide,
      onThumbnailClick: handleThumbnailClick,
      renderItem: customRenderItem,
    };
  }

  return (
    <>
      <ImageGallery {...settings} />
      {modalIsOpen && (
        <div className='modal' onClick={closeModal}>
          <img src={`${url}${images[currentImageIndex].field_media_image_export}`} alt='' onClick={(e) => e.stopPropagation()} />
        </div>
      )}
    </>
  );
};

export default Gallery;
