import React, { useEffect, useState } from 'react';
import axios from '../api';
import { Link } from 'react-router-dom';
import doveDormireWhite from '../../assets/icons/doveDormireWhite.svg';
import BarLoader from 'react-spinners/BarLoader';
import eventiERassegneIcon from '../../assets/icons/eventiERassegneWhite.svg';
import newsIcon from '../../assets/icons/newsWhite.svg';
import museiECulturaIcon from '../../assets/icons/museiECulturaWhite.svg';
import percorsiIcon from '../../assets/icons/itinerariTuristiciWhite.svg';
import doveMangiareIcon from '../../assets/icons/doveMangiareWhite.svg';
import doveDormireIcon from '../../assets/icons/doveDormireWhite.svg';
import shoppingIcon from '../../assets/icons/shoppingWhite.svg';
import itinerariIcon from '../../assets/icons/itinerariTuristiciWhite.svg';
import classNames from 'classnames';
import dove from '../../assets/icons/dove.svg';
import calendario from '../../assets/icons/calendario.svg';
import { url } from '../../url';

export const MappaDellaCittaAroundCard = (props) => {
  // console.log(props.data)
  // console.log(props.filter)
  // const currentLanguage = localStorage.getItem("i18nextLng");
  // const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(0);
  // const pageSize = 10; // numero di elementi per pagina
  // const eventsPerPage = 1000; // limite massimo di eventi da recuperare per ogni chiamata all'API
  // const [hasMoreData, setHasMoreData] = useState(true);
  //
  // useEffect(() => {
  //     setIsLoading(true);
  //     axios
  //         .get(
  //             `/mappa?page=${currentPage}&pageSize=${pageSize}`
  //         )
  //         .then((response) => {
  //             setData((prevData) => [...prevData, ...response.data.rows]);
  //             setTotalPages(response.data.pager.total_pages);
  //             if (currentPage === response.data.pager.total_pages) {
  //                 setHasMoreData(false);
  //             }
  //             setIsLoading(false);
  //         })
  //         .catch((error) => console.log(error));
  // }, [currentPage, currentLanguage, pageSize]);
  //
  // async function fetchAllEvents() {
  //     const allEvents = [];
  //     const response = await axios.get(
  //         `/mappa?page=1&pageSize=1`
  //     );
  //     const totalEvents = response.data.pager.total_items;
  //     const maxEventsToFetch = Math.min(eventsPerPage, totalEvents - data.length);
  //     const maxPagesToFetch = Math.ceil(maxEventsToFetch / pageSize);
  //     for (let i = 0; i <= maxPagesToFetch; i++) {
  //         const response = await axios.get(
  //             `/mappa?page=${i}&pageSize=${pageSize}`
  //         );
  //         allEvents.push(...response.data.rows);
  //     }
  //     return allEvents;
  // }
  //
  // useEffect(() => {
  //     if (hasMoreData && data.length < eventsPerPage) {
  //         setIsLoading(true);
  //         fetchAllEvents().then((allEvents) => {
  //             setData(allEvents);
  //             const totalEvents = allEvents.length;
  //             setHasMoreData(totalEvents < totalEvents);
  //             setIsLoading(false);
  //         });
  //     }
  // }, [data.length, eventsPerPage, hasMoreData, currentLanguage]);
  const getDetailLink = (data) => {
    if (!data || !data.type || !data.id) {
      return null;
    }
    let link = null;

    switch (data.type) {
      case 'eventi':
        link = `/eventi-e-rassegne/${data.id}`;
        break;
      case 'musei_e_cultura':
        link = `/musei-e-cultura/${data.id}`;
        break;
      case 'punti_di_interesse':
        link = `/punti-di-interesse/${data.id}`;
        break;
      case 'dove_mangiare':
        link = `/dove-mangiare/${data.id}`;
        break;
      case 'dove_dormire':
        link = `/dove-dormire/${data.id}`;
        break;
      case 'sapori_e_shopping':
        link = `/sapori-e-shopping/${data.id}`;
        break;
      default:
        link = `/dettaglio/${data.id}`;
        break;
    }

    return link;
  };

  const getDetailImage = (location) => {
    if (!location || !location.type || !location.id) {
      return null;
    }
    let src = null;
    switch (location.type) {
      case 'eventi':
        src = eventiERassegneIcon;
        break;
      case 'rassegna':
        src = eventiERassegneIcon;
        break;
      case 'news':
        src = newsIcon;
        break;
      case 'musei_e_cultura':
        src = museiECulturaIcon;
        break;
      case 'punti_di_interesse':
        src = percorsiIcon;
        break;
      case 'dove_mangiare':
        src = doveMangiareIcon;
        break;
      case 'dove_dormire':
        src = doveDormireIcon;
        break;
      case 'sapori_e_shopping':
        src = shoppingIcon;
        break;
      case 'percorsi_turistici':
        src = itinerariIcon;
        break;
    }

    return src;
  };

  const getTypeBackground = (location) => {
    if (!location || !location.type || !location.id) {
      return null;
    }
    let src = null;
    switch (location.type) {
      case 'eventi':
        src = 'bg-fanoMarkerBlu';
        break;
      case 'rassegna':
        src = 'bg-fanoMarkerBlu';
        break;
      case 'news':
        src = 'bg-fanoMarkerBlu';
        break;
      case 'musei_e_cultura':
        src = 'bg-fanoMarkerRosso';
        break;
      case 'punti_di_interesse':
        src = 'bg-fanoMarkerBlu';
        break;
      case 'dove_mangiare':
        src = 'bg-fanoMarkerArancione';
        break;
      case 'dove_dormire':
        src = 'bg-fanoMarkerArancione';
        break;
      case 'sapori_e_shopping':
        src = 'bg-fanoMarkerArancione';
        break;
      case 'percorsi_turistici':
        src = 'bg-fanoMarkerBlu';
        break;
    }

    return src;
  };
  return (
    <div className={'relative w-full flex gap-6 snap-x snap-mandatory overflow-x-auto pb-14 pt-4'}>
      <div className='snap-center shrink-0 -m-2'>
        <div className='shrink-0 w-4 sm:w-48'></div>
      </div>
      {props.data.map((doveDormireList, i) => (
        <div key={i} className={classNames('relative snap-center shrink-0 first:ml-8 last:mr-8 transition-all -mr-3 w-[200px] mr-10', {})}>
          <Link to={`${getDetailLink(doveDormireList)}`}>
            <img className={classNames('mb-5 w-full h-[280px] object-cover shadow-home', {})} src={`${url}${doveDormireList.field_media_image_export}`} alt='Slide' />
            <div className={'titolo break-words text-center'}>
              <h1 className='font-bold text-lg leading-tight mb-2 font-roboto_slab text-fanoBlue text-[25px] uppercase'>
                {doveDormireList.titolo?.slice(0, 30)}
                {doveDormireList.titolo?.length > 30 ? '...' : ''}
              </h1>
              <p className={'text-fanoBlue'}>{doveDormireList.distance}</p>
            </div>
            <div className={`${getTypeBackground(doveDormireList)} absolute bg-fanoBlue top-0 right-0 p-2 rounded-[10px]`}>
              <img src={`${getDetailImage(doveDormireList)}`} className={'w-6 h-6'} />
            </div>
            {doveDormireList.dove && (
              <div className={'dove'}>
                <p className='text-gray-700 text-base leading-tight'></p>
                <img src={dove} alt='dove' className={'w-4 inline-block mr-2'} />
                <span className='text-[14px] break-words'>
                  {doveDormireList.dove?.slice(0, 30)}
                  {doveDormireList.dove?.length > 30 ? '...' : ''}
                </span>
              </div>
            )}
            {doveDormireList.data_inizio && doveDormireList.data_fine && (
              <div className={'data'}>
                <p className='text-gray-700 text-base leading-tight'></p>
                <img src={calendario} alt='calendario' className={'w-4 inline-block mr-2'} />
                <span className='text-[14px]'>{doveDormireList.data_inizio}</span> - <span className='text-[14px]'>{doveDormireList.data_fine}</span>
              </div>
            )}
          </Link>
        </div>
      ))}
      <div className='snap-center shrink-0'>
        <div className='shrink-0 w-4 sm:w-48'></div>
      </div>
      {isLoading && (
        <div className='flex justify-center items-center h-44'>
          <BarLoader color={'#2b6cb0'} size={25} />
        </div>
      )}
    </div>
  );
};
