import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import menuIcon from "../../assets/icons/menuOpen.svg";
import comuneETerritorio from "../../assets/icons/comune_e_territorio.svg";
import mappaDellaCitta from "../../assets/icons/mappa_della_citta.svg";
import eventiERassegne from "../../assets/icons/eventi_e_rassegne.svg";
import itinerariTuristici from "../../assets/icons/itinerari_turistici.svg";
import museiECultura from "../../assets/icons/musei_e_cultura.svg";
import ospitalitaESapori from "../../assets/icons/ospitalita_e_sapori.svg";
import menuButton from "../../assets/icons/keyboard_double_arrow_up.svg";
import { url } from "../../url";

import "./Menu.css";
import axios from "../api";

const Menu = () => {
  const currentLanguage = localStorage.getItem("i18nextLng");

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [height, setHeight] = useState("0");
  const [top, setTop] = useState("[540px]");
  const [padding, setPadding] = useState("0");
  const [paddingTop, setPaddingTop] = useState("0");
  const [paddingBottom, setPaddingBottom] = useState("0");
  const [rotation, setRotation] = useState("360");

  function toggleHeight() {
    setHeight(height === "0" ? "57vh" : "0");
    setTop(top === "[540px]" ? "0" : "[540px]");
    setPadding(padding === "0" ? "5" : "0");
    setPaddingTop(paddingTop === "0" ? "4" : "0");
    setPaddingBottom(paddingBottom === "0" ? "4" : "0");
    setRotation(rotation === "360" ? "180" : "360");
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    toggleHeight();
  };

  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get(`${url}${currentLanguage}/api/menu`)
      .then((response) => setData(response.data))
      .catch((error) => console.log(error));
  }, []);

  console.log("DATA", data);

  return (
    <div className="menu h-[20vh] overflow-hidden">
      <div
        style={{ backgroundColor: "#fff" }}
        className="absolute text-center w-full bottom-0 overflow-hidden bg-white b-t-l-r-15 pt-5 pl-5 pr-5"
      >
        <ul
          className={`h-${height} top-${top} z-10 relative items-center flex flex-wrap duration-300 pt-${paddingTop} pb-${paddingBottom}`}
        >
          {data &&
            data?.map((item, index) => {
              return (
                <li
                  className={`flex w-1/3 h-32 pb-3 px-2`}
                  onClick={toggleMenu}
                  key={index}
                >
                  <Link
                    className={
                      "bg-white shadow-fanoShadow w-full h-full flex flex-wrap justify-center items-center rounded-2xl"
                    }
                    to={item.url}
                  >
                    <img
                      src={`${url}${item.icona}`}
                      className={"w-[40px]"}
                      alt={item.titolo}
                    />
                    <span
                      className={`w-full font-roboto_slab text-mazaraPrimary text-[15px] font-bold leading-5 px-3`}
                    >
                      {item.titolo}
                    </span>
                  </Link>
                </li>
              );
            })}
        </ul>
        <button
          className={`bg-white pb-5 w-full text-center z-10 bottom-[-15px] left-0 fixed`}
          onClick={toggleMenu}
        >
          <div
            className={
              " w-16 h-16 m-auto rounded-full border-[4px] flex justify-center content-center align-middle flex-wrap items-center border-mazaraPrimary"
            }
          >
            <img
              className={`w-[40px] rotate-${rotation} duration-300`}
              src={menuButton}
            />
          </div>
        </button>
      </div>
    </div>
  );
};

export default Menu;
